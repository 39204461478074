import React, { useState } from "react"
import { navigate } from "gatsby"
import Typed from "react-typed"
import styled from "styled-components"

import "./error.css"

const ColoredLink = styled.span`
  color: ${props => (props.color ? props.color : "yellow")};
`

const Error = inputProps => {
  const [history, setHistory] = useState(new Set())
  const [values, setValues] = useState({
    terminalInputText: "",
    finished: false,
    index: 0,
  })
  const { finished, index, terminalInputText } = values

  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = text => {
    switch (text.toLowerCase()) {
      case "home":
        navigate("/")
        break
      case "about":
        navigate("/about")
        break
      case "resume":
        navigate("/resume")
        break
      case "experience":
        navigate("/experience")
        break
      case "skills":
        navigate("/skills")
        break
      case "projects":
        navigate("/projects")
        break
      case "linkedin":
        window.open("https://www.linkedin.com/in/devonyu", "_blank")
        updateTerminal(true, `Opening Linkedin page in new tab...`, "green")
        setValues({ ...values, terminalInputText: "" })
        break
      case "github":
        window.open("https://www.github.com/devonyu", "_blank")
        updateTerminal(true, `Opening Github page in new tab...`, "green")
        setValues({ ...values, terminalInputText: "" })
        break
      case "facebook":
        window.open("https://www.facebook.com/devonyu", "_blank")
        updateTerminal(true, `Opening Facebook page in new tab...`, "green")
        setValues({ ...values, terminalInputText: "" })
        break
      case "ig":
        window.open("https://www.instagram.com/devonyu", "_blank")
        updateTerminal(true, `Opening Instagram page in new tab...`, "green")
        setValues({ ...values, terminalInputText: "" })
        break
      default:
        updateTerminal(
          true,
          `${text} is not a valid input, Please try again`,
          "yellow"
        )
        setValues({ ...values, terminalInputText: "" })
    }
  }

  const handleKeyPress = e => {
    if (e.keyCode === 13 && terminalInputText.length) {
      handleSubmit(terminalInputText)
    }
  }

  const terminalInput = [
    `Accessing devonyu.com${inputProps.urlPath}..`,
    "Sorry, This specific page does not exist or has changed...",
  ]
  const terminalOutput = [
    <span>
      <ColoredLink color="gray">
        Searching for files...
        <br />
        SERVER ERROR
      </ColoredLink>
      <ColoredLink color="red">
        <br />
        404 Page Not Found
      </ColoredLink>
    </span>,
    <ColoredLink color="gray">
      Please click on any of the navigation links on the page or...
      Alternatively you can type the one of following:{" "}
      <ColoredLink color="orange">
        home, resume, about, experience, skills, projects,
      </ColoredLink>{" "}
      <ColoredLink color="deepskyblue">
        linkedin, github, facebook, ig
      </ColoredLink>
    </ColoredLink>,
  ]

  const updateTerminal = (additionalInput, text, color) => {
    let newHistory = history
    if (!finished) {
      newHistory.add(`$ ${terminalInput[index]}`)
      newHistory.add(terminalOutput[index])
      setHistory(newHistory)
    }
    if (index === 1) {
      setValues({ ...values, finished: true })
    } else if (!finished) {
      setValues({ ...values, index: 1 })
    }
    if (additionalInput) {
      newHistory.add(<ColoredLink color={color}>{text}</ColoredLink>)
      setHistory(newHistory)
    }
  }

  const Prompt = string => (
    <Typed
      typeSpeed={50}
      backSpeed={20}
      strings={index < 2 ? [terminalInput[index]] : []}
      shuffle={false}
      backDelay={1}
      fadeOut={false}
      fadeOutDelay={100}
      startDelay={1000}
      onStringTyped={!finished ? updateTerminal : null}
      showCursor
      stopped={finished}
      cursorChar="&#9608;"
    />
  )

  return (
    <div class="terminal-window">
      <header>
        <div class="button red"></div>
        <div class="button yellow"></div>
        <div class="button green"></div>
      </header>
      <section class="terminal">
        <div class="history"></div>
        {history &&
          [...history].map((string, idx) => (
            <span key={idx}>
              {string}
              <br />
            </span>
          ))}
        <span class="prompt">
          $&nbsp;
          {!finished ? <Prompt /> : null}
          {finished ? (
            <Typed
              strings={[
                "home",
                "resume",
                "about",
                "experience",
                "skills",
                "projects",
                "linkedin",
                "github",
                "facebook",
                "ig",
              ]}
              typeSpeed={40}
              backSpeed={50}
              fadeOutDelay={200}
              attr="placeholder"
              loop
              shuffle={true}
            >
              <input
                autoFocus
                class="prompt"
                name="terminalInputText"
                onChange={handleInputChange}
                value={terminalInputText}
                onKeyDown={handleKeyPress}
                type="text"
                onSubmit={handleSubmit}
              />
            </Typed>
          ) : null}
        </span>
      </section>
    </div>
  )
}

export default Error
